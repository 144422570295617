<template>
    <div id="m_guide">
        <div class="top d_flex">
            <div class="logo"><img :src="$store.state.tenantInfo.logo | fullPath" alt=""></div>
            <div class="right_content d_flex">
                <template v-if="!isLogin">
                    <span class="login" @click="$router.push('/m_login')">登录</span>
                    <span class="registered" @click="$router.push('/m_register')">注册</span>
                </template>
                <div class="user" v-else><img src="@/assets/img/mobile/10.png" alt=""></div>
                <span @click="show = !show"><img src="@/assets/img/mobile/nav_bar.png" alt=""></span>
            </div>
        </div>
        <div class="subnav_content fr">
            <div class="user_temp">
                <div id="guide">
                    <div class="admin_main_block">
                        <div class="user_temp_title">源码搭建</div>
                        <div class="admin_table_main">
                            <div class="page_main">
                                <div>
                                    <span style="font-size: x-large; color: rgb(249, 150, 59);">源码部署,拥有属于自己的网站！</span>
                                </div>
                                <div style="text-align: center;">
                                    <br>
                                </div>
                                <span style="font-size: large; color: rgb(194, 79, 74);">
                                    网站完全独立控制，管理操作简单。<br>
                                    全天24小时自动售单，无需人工干预。<br>
                                    欢迎四海之内各位有识之士参与。<br>
                                </span><br><br>
                                <span style="font-weight: bold; font-size: large; color: rgb(70, 172, 200);">源码搭建优势</span><br>
                                <span style="color: rgb(70, 172, 200);">
                                    1、平台技术支持，24小时候技术在线维护。<br>
                                    2、我们提供稳定、安全、低价的快递费、礼品货源。<br>
                                    3、独立域名，独立后台，自由管理。<br>
                                    4、前景非常可观，客源稳定，稳定收入。<br>
                                    5、搭建成本低，可以提供服务器，域名，维护，升级。<br>
                                    6、源码搭建，网站API接口免费提供。
                                </span><br><br>
                                <span style="font-size: large;">
                                    <br><br>
                                    <span style="font-weight: bold;color:red;">一、源码搭建（一手源码）:</span>
                                </span><br>
                                <div style="color:red;"> 1.主站源码部署价格，详情咨询招商经理（搭建属于自己的礼品网）
                                    <br>2.无仓库可对接发B网API，无需自己建仓
                                </div>
                                <div><br></div>
                                <span style="font-size: large;">
                                    <br><br>
                                    <span style="font-weight: bold;color:red;">二、分站加盟:</span>
                                </span><br>
                                <div style="color:red;"> 1.联系客服申请加盟分站
                                    <br>2.开通分站需要您提供一个登陆后台的账户名，一个登陆后台的密码，客服手机号码。
                                    <br>3.上线分站进行管理运营、设置快递费用、礼品价格、客服信息修改等。
                                </div>
                                <div><br></div>
                                <div>
                                    <br>
                                    <span style="font-size: large; font-weight: bold;">二、更多具体信息请联系招商经理</span>
                                </div><br>
                                <!-- <p style="text-align: center;">
                                    <span style="font-size: x-large;">分站咨询 电话：{{$store.state.tenantInfo.phone}}</span>
                                </p> -->
                                <div>
                                    <img src="@/assets/img/fz.jpg" style="width: 200px;"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom_content d_flex">
            <div class="b_item">
                <div class="d_flex">
                    <img src="@/assets/img/bottom1.png" alt="">
                    <p>
                        <span>降低成本</span><br>
                        <span>帮你降低50%发货成本</span>
                    </p>
                </div>
                <div class="d_flex">
                    <img src="@/assets/img/bottom2.png" alt="">
                    <p>
                        <span>保证品质</span><br>
                        <span>注重品质，物廉价美</span>
                    </p>
                </div>
            </div>
            <div class="b_item">
                <div class="d_flex">
                    <img src="@/assets/img/bottom4.png" alt="">
                    <p>
                        <span>去除库存</span><br>
                        <span>回收库存、去礼品库</span>
                    </p>
                </div>
                <div class="d_flex">
                    <img src="@/assets/img/bottom3.png" alt="">
                    <p>
                        <span>多种选择</span><br>
                        <span>低价礼品、配重礼品、高端礼品</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="friend_link">
            <span>友情链接：</span>
            <a :href="item.url" v-for="item in linksList" target="_blank">{{item.name}}</a>
        </div>
        <div class="copyright" v-if="$store.state.tenantInfo.beianhao">
            <img src="@/assets/img/guohui.png" alt="">
            {{$store.state.tenantInfo.beianhao}}
        </div>
        <transition name="fade">
            <div class="mask" v-if="show" @click="show=false">
            </div>
        </transition>
        <transition name="fade1">
            <div class="mask_left" v-if="show">
                <div @click="$router.push('/m_index')">首页</div>
                <div @click="$router.push('/m_goodsall')">礼品商城</div>
                <div @click="$router.push('/member/index')">会员中心</div>
                <div @click="$router.push('/member/vip')">收费标准</div>
                <div @click="$router.push('/member/guide')">代发指南</div>
                <!-- <div @click="$router.push('/member/other_site')" >源码搭建</div> -->
                <div @click="openQQ">在线客服</div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
            isLogin:false,
            show:false,
            article:{
                title:'',
                conent:'',
            },
        }
    },
    created(){
        this.getArticleList();
        let token = localStorage.getItem('dftToken')
        if(token){
            this.isLogin = true
        }else{
            this.isLogin = false
        }
        // this.IsPC()
    },
    mounted(){},
    watch: {},
    methods: {
        //获取最新一条代发指南
        getArticleList(){
            this.$request.post({
                url:'/bus/article/public/list',
                params:{
                    pageNo:1,
                    pageSize:1,
                    type:6,
                    isConent:1,
                },
                success: res => {
                    if(res.list.length>0){
                        this.article = res.list[0];
                    }
                }
            })
        },
        openQQ() {
            var url = 'tencent://message/?uin=' + this.$store.state.tenantInfo.qq + '&amp;Site=&amp;Menu=yes';
            window.open(url,'_self')
        },
        IsPC(){  
            var userAgentInfo = navigator.userAgent;
            var Agents = new Array("Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod");  
            var flag = true;  
            for (var v = 0; v < Agents.length; v++) {  
                if (userAgentInfo.indexOf(Agents[v]) > 0) { flag = false; break; }  
            }  
            if(flag){
                this.$router.push('/member/other_site')
            }
        },
    }
}
</script>

<style lang="scss" scoped>
#m_guide{
    padding-top: 44px;
    .top{
        position: fixed;
        top: 0;
        background: #ffffff;
        width: 100%;
        box-sizing: border-box;
        justify-content: space-between;
        align-items: center;
        padding: 0 14px;
        height: 44px;
        border: 1px solid #F5F5F5;
        z-index: 10;
        .logo{
            align-items: center;
            width: 57.6px;
            img{
                width: 100%;
                display: block;
            }
        }
        .right_content{
            align-items: center;
            span{
                &:last-child{
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    img{
                        width: 100%;
                    }
                }
            }
            .login{
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
                background: #FF8800;
                border-radius: 12px;
                padding: 2px 10px;
            }
            .registered{
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FF8800;
                padding: 2px 10px;
                border: 1px solid #ff8800;
                border-radius: 12px;
                margin: 0 14px 0 5px;
            }
            .user {
                width: 18px;
                height: 18px;
                display: block;
                margin-right: 18px;
                img{
                    width: 100%;
                    height: 100%;
                    display: block;
                }
            }
        }
    }
    .subnav_content{
        width: auto;
        background: #fff;
        margin-bottom: 0;
        padding-bottom: 20px;
        .user_temp{
            #guide{
                .user_temp_title{
                    border-bottom:0;
                    padding-left:0;
                    margin-bottom: 30px;
                    padding-bottom: 0;
                    font-weight: 700;
                }
                .db{
                    display: block;
                }
                .admin_main_block{
                    padding: 30px 22px 0;
                    .admin_table_main{
                        .p1{
                            font-weight: bold;
                        }
                        .font1{
                            color: #f9963b;
                        }
                    }
                }
            }
        }
    }
    .bottom_content{
        padding: 0 14px;
        margin-top: 10px;
        .b_item{
            flex: 1;
            div{
                img{
                    width: 74px;
                }
                p{
                    margin-top: 15px;
                    span{
                        &:first-child{
                            color: #000000;
                            font-size: 14px;
                            font-weight: bold;
                        }
                        &:last-child{
                            font-size: 10px;
                            color: #999;
                        }
                    }
                }
            }
        }
    }
    .friend_link{
        margin-top: 15px;
        text-align: center;
        span{
            font-size: 10px;
            color: #333333;
            &:first-child{
                color: #666666;
            }
        }
        a{
            margin-right: 5px;
            font-size: 10px;
        }
    }
    .copyright{
        text-align: center;
        font-size: 8px;
        color: #333333;
        margin-top: 9px;
        padding-bottom: 42px;
        img{
            height: 16px;
        }
    }
    .mask{
        position: fixed;
        top: 44px;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0,0,0,0.5);
        z-index: 11;
    }
    .mask_left{
        width: 35%;
        text-align: center;
        position: fixed;
        top: 44px;
        background: #fff;
        bottom: 0;
        right: 0;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        z-index: 11;
        div{
            padding: 9px 0;
            border-bottom: 1px solid #eee;
        }
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade1-enter, .fade1-leave-to {
        transform: translateX(100%);
    }
    .fade1-enter-active, .fade1-leave-active {
        transition: all .5s;
    }
}
</style>